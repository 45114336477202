import { Link } from "gatsby-link";
import React from "react";

const EncordAccelerateSection = ({
  imgSrc,
  imgAlt,
  imageWidth,
  imageHeight,
  encordType,
  encordTypeText,
  encordItems,
}) => {
  return (
    <section className="flex flex-col items-center max-w-5xl mx-auto mt-10 lg:mt-12 lg:mb-5 sm:mb-10 mb-14 md:my-16">
      <img
        src={imgSrc}
        alt={imgAlt || "img"}
        loading="lazy"
        width={imageWidth || 0}
        height={imageHeight || 0}
      />

      <h3 className="text-white uppercase text-xs font-medium lg:pt-4 pt-2.5">
        {encordType}
      </h3>
      <h4 className="text-white text-2.25xl sm:text-3.75xl tracking-tighter_3 sm:leading-10 font-semibold pt-5 text-center sm:mb-10  mb-8">
        {encordTypeText}
      </h4>

      <div className="flex lg:flex-row flex-col lg:flex-wrap max-w-full lg:pt-5 pt-3">
        {encordItems?.map(
          ({ feature_description, feature_label, feature_icon }) => {
            return (
              <div className="text-white lg:w-2/4 sm:w-full flex sm:flex-row flex-col items-center md:items-start gap-3.5 2xl:p-6 sm:p-6 px-6 py-4 lg:mb-12">
                <img
                  src={feature_icon?.url}
                  width={feature_icon?.dimensions?.width || 0}
                  height={feature_icon?.dimensions?.height || 0}
                  loading="lazy"
                  className="w-14 h-14 sm:w-22 sm:h-22"
                  alt={feature_icon?.alt || "feature_icon"}
                />
                <section className="text-center sm:text-left">
                  <h4 className="text-2xl tracking-tighter_1 font-semibold">
                    {feature_label?.text}
                  </h4>
                  <p className="lg:max-w-sm sm:max-w-lg mt-2.5 text-base">
                    {feature_description?.text}
                  </p>
                </section>
              </div>
            );
          }
        )}
      </div>
    </section>
  );
};

export default EncordAccelerateSection;
