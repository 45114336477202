import { Link } from "gatsby-link";
import React from "react";

const CardSection = ({
  heading,
  description,
  imgSrc,
  imgAlt,
  videoSrc,
  isReverse,
  imageWidth,
  imageHeight,
  learnMoreLink,
}) => {
  return (
    <div
      className={`lg:max-w-[356px] sm:max-w-2xl max-w-sm flex ${
        isReverse ? "md:flex-row-reverse" : "md:flex-row"
      } lg:flex-col sm:flex-row flex-col overflow-hidden rounded-2xl shadow-header bg-white md:mb-6 sm:mb-5 mb-7 lg:min-h-[417px] lg:h-auto w-full`}
    >
      <section className="flex lg:flex-none">
        {videoSrc ? (
          <VideoComponent loop autoPlay muted>
            <source
              src={videoSrc}
              type="video/mp4"
              className="w-full h-56 bg-gray-1800 object-cover"
            />
          </VideoComponent>
        ) : (
          <img
            src={imgSrc}
            alt={imgAlt || "img"}
            className="w-full h-56 bg-gray-1800 object-cover"
            loading="lazy"
            width={imageWidth || 0}
            height={imageHeight || 0}
          />
        )}
      </section>

      <div className="flex flex-col justify-center flex-1 py-5 px-4 space-y-3 text-left lg:flex-none">
        <h4 className="sm:text-2xl text-xl tracking-tightest font-semibold text-gray-2500">{heading}</h4>

        <p className="max-w-md text-base text-gray-600 line-clamp-3">
          {description}
        </p>

        <div className="flex-end">
          <Link
            to={learnMoreLink}
            className="font-bold text-purple-1500 flex items-center w-max text-base"
          >
            <a src={learnMoreLink}>Learn more</a>
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.0259 8.28787L4.53439 13.3581C4.51743 13.3715 4.49705 13.3798 4.47558 13.382C4.45411 13.3843 4.43243 13.3805 4.41304 13.371C4.39364 13.3616 4.3773 13.3468 4.36591 13.3285C4.35452 13.3101 4.34853 13.289 4.34863 13.2674V12.1543C4.34863 12.0837 4.38175 12.016 4.43647 11.9728L9.62045 7.92499L4.43647 3.87715C4.38031 3.83395 4.34863 3.76627 4.34863 3.69571V2.5826C4.34863 2.48612 4.45951 2.43284 4.53439 2.49188L11.0259 7.56211C11.0811 7.60515 11.1257 7.6602 11.1564 7.72308C11.1871 7.78596 11.203 7.85501 11.203 7.92499C11.203 7.99496 11.1871 8.06401 11.1564 8.1269C11.1257 8.18978 11.0811 8.24483 11.0259 8.28787Z"
                fill="#5658DD"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardSection;
